<template>
  <v-sheet>
    <v-card>
      <v-card-text>
        <div class="mb-4">
          <v-text-field v-model="form.title" dense hide-details label="Titlu" outlined/>
          <error-messages :errors="errors.title"/>
        </div>

        <div class="mb-4">
          <label>Distribuitor</label>
          <wysiwyg v-model="form.account_benefits_reseller"/>
          <error-messages :errors="errors.account_benefits_reseller"/>
        </div>
        <div class="mb-4">
          <label>Companie</label>
          <wysiwyg v-model="form.account_benefits_company"/>
          <error-messages :errors="errors.account_benefits_company"/>
        </div>
        <div class="mb-4">
          <label>Persoana Fizica</label>
          <wysiwyg v-model="form.account_benefits_end_user"/>
          <error-messages :errors="errors.account_benefits_end_user"/>
        </div>
        <div class="mb-4">
          <label>It-ist</label>
          <wysiwyg v-model="form.account_benefits_it"/>
          <error-messages :errors="errors.account_benefits_it"/>
        </div>
        <div class="mb-4">
          <label>Institutie publica</label>
          <wysiwyg v-model="form.account_benefits_public"/>
          <error-messages :errors="errors.account_benefits_public"/>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="primary" class="primary-button" @click="saveSettings">Salvare</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import Wysiwyg from '@/components/general-form/Wysiwyg'

export default {
  components: {
    Wysiwyg,
    ErrorMessages
  },
  data () {
    return {
      loading: false,
      errors: {},
      form: {
        title: '',
        account_benefits_reseller: '',
        account_benefits_company: '',
        account_benefits_end_user: '',
        account_benefits_it: '',
        account_benefits_public: ''
      }
    }
  },
  methods: {
    getForm () {
      this.$http.get('settings/page-account/benefits')
        .then(({data}) => {
          this.form = data
        })
    },
    saveSettings () {
      this.errors = {}
      this.loading = true
      this.$http.post('settings/page-account/benefits', this.form)
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})

        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            }, {root: true})
          } else {
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            }, {root: true})
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.getForm()
  }
}
</script>
