<template>
  <v-tabs class="homepage-settings" left vertical>

    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Beneficii</span>
    </v-tab>

    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <benefits-section/>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import BenefitsSection from './components/BenefitsSection'

export default {
  components: {
    BenefitsSection
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.homepage-settings .v-tabs-slider-wrapper {
  right: 0;
  left: inherit !important;
}
</style>
